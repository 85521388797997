import "@fontsource/pt-sans-narrow/400.css";
import "@fontsource/pt-sans-narrow/700.css";
import "react-toastify/dist/ReactToastify.min.css";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "./src/sass/main.scss";
import React from "react";
import RootLayout from "./src/pages/layouts/root.layout";
import { GatsbyBrowser } from "gatsby";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return <RootLayout>{element}</RootLayout>;
};
