import React from "react";
import "./footer.styles.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={`footer`}>
      <div className="footer__copyright">© {currentYear} Zeera By The Bay</div>
    </footer>
  );
};

export default Footer;
